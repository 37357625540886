import { Box, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import DownloadButton from "../common/DownloadButton"

const Header = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      noWrap
      sx={{
        typography: { xs: "h2", md: "h1" },
        textAlign: { xs: "center", md: "left" },
        // padding: "0 0 0 0.5rem",
        color: theme.palette.offWhite.main,
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
      }}
    >
      {children}
    </Typography>
  );
};

const Subtitle = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        typography: { sm: "body1", xs: "body2" },
        color: theme.palette.offWhite.main,
        flexGrow: 1,
        padding: {xs: "0 0 1rem 2rem", sm: "0 0 1rem 0"},
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
      }}
    >
      {children}
    </Typography>
  );
};

const Cover = () => {
  return (
    <Box
      sx={{
        padding: "4rem 0rem 0 0",
        display: "flex",
        flexDirection: "column",
        height: { xs: "850px", sm: "700px" },
      }}
    >
      <Box>
        <Header>Decentralized</Header>
        <Header>Edge Computing:</Header>
        <Header>Your Data, Your Control</Header>
      </Box>
      <Box
        sx={{
          padding: {
            md: "3rem 20rem 0 0",
            sm: "3rem 2rem 0 0",
            xs: "3rem 2rem 0rem 0",
          },
        }}
      >
        <Subtitle>
          MECAnywhere, with its seamlessly integrated platform empowers your
          business to thrive in the digital age.
        </Subtitle>
        <Subtitle>
          Say goodbye to latency and data security concerns, and hello to
          unparalleled performance.
        </Subtitle>
      </Box>
      {/* <DownloadButton isDownloadPage={false}/> */}
    </Box>
  );
};

export default Cover;
