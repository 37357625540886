import { Stack } from "@mui/material";
import AppFeatures from "./AppFeatures";
import AppDescription from "./AppDescription";
import Cover from "./Cover";
import Story from "./Story";

//the width and height is compulsory for some computer?!
const Home = () => {
  return (
      <Stack className="home-stack" sx={{width: "100%", height: "100%"}}>
        <Cover className="home-cover" />
        <AppFeatures className="home-app-features" />
        <AppDescription className="home-app-description" />
        <Story className="home-story" />
      </Stack>
  );
};

export default Home;
