import paragraphData from "./paragraph_data.json";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

const Header = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      noWrap
      sx={{
        typography: { xs: "h2", md: "h1" },
        textAlign: "center",
        padding: "6rem 0 3.5rem 0.5rem",
        color: theme.palette.darkBlack.main,
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
      }}
    >
      {children}
    </Typography>
  );
};

const ContainerTitle = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="h3"
      sx={{
        whiteSpace: "normal",
        color: theme.palette.darkBlack.main,
      }}
    >
      {children}
    </Typography>
  );
};

const ContainerContent = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="body1"
      sx={{
        color: theme.palette.darkBlack.main,
      }}
    >
      {children}
    </Typography>
  );
};

const Container = ({ title, content }) => {
  return (
    <Box sx={{ padding: "1rem 0rem 0rem 0rem" }}>
      <Box>
        <ContainerTitle>{title}</ContainerTitle>
      </Box>
      <Box sx={{ padding: "0.5rem 0rem 0rem 0rem" }}>
        <ContainerContent>{content}</ContainerContent>
      </Box>
    </Box>
  );
};

const AppDescription = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.cerulean.main,
        width: "100vw",
        transform: "translateX(-50%)",
        marginLeft: "50%",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        paddingBottom: "6rem",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: "90rem",
          height: "100%",
        }}
      >
        <Box sx={{}}>
          <Header>MECAnywhere: Edge Computing Redefined.</Header>
        </Box>
        <Grid
          className="main-wrapper"
          container
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            padding: "2rem 0.5rem 2rem 0.5rem",
          }}
        >
          <Grid
            className="first-half-image"
            item
            md={7}
            xs={12}
            sx={{
              order: { xs: 1, md: 1 },
              justifyContent: "center",
              alignItems: "center",
              display: { md: "flex" },
              position: "relative",
              // left: { xs: "0", md: "-3rem" },
              paddingRight: { xs: "0", md: "2rem" },
              padding: "2rem 0 2rem 0",
              // paddingBottom: "2rem",
              filter: "drop-shadow(30px 10px 4px #5651BD)",
              width: { xs: "100%", md: "28rem" }, // 100% width on extra-small screens, fixed on others
              height: "auto", // Height auto for maintaining aspect ratio
              overflow: "hidden",
            }}
          >
            <img
              src={`../../images/features/dashboard.png`}
              alt="dashboard"
              style={{
                width: "100%",
                height: "auto", // Adjust height automatically to maintain aspect ratio
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid
            className="first-half-content"
            item
            md={5}
            xs={12}
            sx={{ position: "relative", order: { xs: 2, md: 2 }, paddingLeft: { xs: "0", md: "2rem" }, }}
          >
            <Container
              title={paragraphData[0].title}
              content={paragraphData[0].content}
            />
            <Container
              title={paragraphData[1].title}
              content={paragraphData[1].content}
            />
          </Grid>
          <Grid
            className="second-half-content"
            item
            md={5}
            xs={12}
            sx={{
              padding: "0 0 0 0rem",
              position: "relative",
              order: { xs: 4, md: 3 },
            }}
          >
            <Container
              title={paragraphData[2].title}
              content={paragraphData[2].content}
            />
            <Container
              title={paragraphData[3].title}
              content={paragraphData[3].content}
            />
          </Grid>
          <Grid
            className="second-half-image"
            item
            md={7}
            xs={12}
            sx={{
              order: { xs: 3, md: 4 },
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              filter: "drop-shadow(30px 10px 4px #5651BD)",
              height: "auto",
              display: { md: "flex" },
              width: { xs: "100%", md: "28rem" },
              paddingLeft: { xs: "0", md: "2rem" },
              padding: "2rem 0 2rem 0",
            }}
          >
            <img
              src={`../../images/features/tasks.png`}
              alt="dashboard"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AppDescription;
