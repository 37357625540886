import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import cover from "../images/wallpaper.jpg";

const CoverImage = () => {
  const location = useLocation();
  return location.pathname === "/" ? (
    <Box className="cover"
      sx={{
        position: 'absolute',
        width: "100%", 
        height: {xs: "850px", sm: "700px"},
        backgroundImage: `url(${cover})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        zIndex: 0,
      }}
    />
  ) : null;
};

export default CoverImage;
