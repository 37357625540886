import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { navBarHeight, innerWrapperWidth } from "../utils/constants";
import { ReactComponent as SbipLogoWithText } from "../images/sbipLogoWithText.svg";

const pages = [
  // "Pricing",
  "Download",
  // "About",
];

const NavBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const location = useLocation();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlePageNavigation = (page) => {
    navigate(`/${page.toLowerCase()}`);
    handleCloseNavMenu();
  };

  const handleLogoClick = () => {
    navigate('/')
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        height: navBarHeight,
        backgroundColor:
          location.pathname === "/"
            ? "transparent"
            : theme.palette.lightBlack.main,
        backdropFilter: "blur(10px)",
      }}
    >
      <Toolbar disableGutters>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: innerWrapperWidth,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: { xs: "none", sm: "flex" }, paddingLeft: "1rem" }}>
              <SbipLogoWithText width="60%" height="60%" style={{ cursor: 'pointer' }} onClick={handleLogoClick} />
            </Box>
            <Box sx={{  display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                onClick={handleOpenNavMenu}
              >
                <MenuIcon sx={{ color: theme.palette.offWhite.main }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                  "& .MuiPaper-root": {
                    backgroundColor: theme.palette.lightBlack.main,
                  },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page}
                    onClick={() => handlePageNavigation(page)}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "flex", sm: "none" }, justifyContent: "center", marginLeft: "-1rem", paddingTop: "0.5rem" }}>
              <SbipLogoWithText width="60%" height="60%" style={{ cursor: 'pointer', color: "white"}} onClick={handleLogoClick} />
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={() => handlePageNavigation(page)}
                  sx={{
                    my: 2,
                    backgroundColor: "transparent",
                    color: theme.palette.offWhite.main,
                    fontFamily: "sans-serif",
                    fontSize: "0.9rem",
                    display: "block",
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: theme.palette.cerulean.main,
                    },
                    letterSpacing: `0.15em`,
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default NavBar;
