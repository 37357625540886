import {
  createTheme,
} from "@mui/material/styles";

const createColor = (mainColor) =>
  createTheme().palette.augmentColor({ color: { main: mainColor } });

const cerulean = "#829CFF";
const deepCerulean = "#6485FF";
const lightPurple = "#6D697D";
const lightBlack = "#292733";
const mediumBlack = "#202028";
const darkBlack = "#18191C";
const mintGreen = "#35D4C7";
const offWhite = "#F7F7F7";
const silver = "#A1B3CF";

const themeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: darkBlack,
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: "1rem",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            // minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: mediumBlack,
    },
    magenta: createColor("#F51474"),
    purple: createColor("#790EFC"),
    cerulean: createColor(cerulean),
    deepCerulean: createColor(deepCerulean),
    violet: createColor("#BC00A3"),
    lightPurple: createColor(lightPurple),
    lightBlack: createColor(lightBlack),
    mediumBlack: createColor(mediumBlack),
    darkBlack: createColor(darkBlack),
    mintGreen: createColor(mintGreen),
    offWhite: createColor(offWhite),
    silver: createColor(silver),
    primary: {
      light: lightBlack,
      main: mediumBlack,
      dark: darkBlack,
      contrastText: `#FFFFFF`,
    },
    secondary: {
      light: `#F51474`, // magenta
      main: `#f9f9f9`,
      dark: `#C5BDBA`,
      contrastText: `#790EFC`, // purple
    },
    text: {
      primary: offWhite,
      secondary: lightPurple,
      // disabled: styles.ttt,
      // hint: styles.tttt,
    },
  },
  typography: {
    fontFamily: [
      `Nunito Sans`,
      `Roboto`,
      `Helvetica`,
      `Arial`,
      `sans-serif`,
    ].join(`,`),
    fontSize: 16,
    h1: {
      fontWeight: 700,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textDecoration: "none",
      fontSize: `3.8rem`,
      letterSpacing: -1,
    },
    h2: {
      fontWeight: 700,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textDecoration: "none",
      fontSize: `2.5rem`,
      // fontFamily: "sans-serif",
      // letterSpacing: -1,
    },
    h3: {
      fontWeight: 700,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textDecoration: "none",
      fontSize: `2rem`,
      fontFamily: "sans-serif",
      letterSpacing: -1,
    },
    h4: {
      fontWeight: 700,
      whiteSpace: "normal",
      wordWrap: "break-word",
      textDecoration: "none",
      fontSize: `1.3rem`,
      fontFamily: "sans-serif",
      letterSpacing: 1,
    },
    body1: {
        wordWrap: "break-word",
        fontWeight: 500,
        textDecoration: "none",
        fontSize: `1.1rem`,
        fontFamily: "sans-serif",
        letterSpacing: "0.1rem", 
    },
    body2: {
      wordWrap: "break-word",
      fontWeight: 500,
      textDecoration: "none",
      fontSize: `0.9rem`,
      fontFamily: "sans-serif",
      letterSpacing: "0.1rem",
    },
    body3: {
      wordWrap: "break-word",
      fontWeight: 500,
      textDecoration: "none",
      fontSize: `0.8rem`,
      fontFamily: "sans-serif",
      letterSpacing: "0.1rem",
    },
  },
};

export default createTheme(themeOptions);
