import { Box } from "@mui/material";
import Footer from "./Footer";
import { innerWrapperWidth, navBarHeight } from "../utils/constants";

const Wrapper = ({ children }) => {
  return (
    <Box
      className="wrapper-outer"
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "hidden",
        paddingTop: navBarHeight, // navbar height
      }}
    >
      <Box
        className="wrapper-inner"
        sx={{
          flexGrow: 1,
          maxWidth: innerWrapperWidth,
          position: "relative",
          width: "100%",
          height: "100%",
          display: 'flex',
          padding: "1rem"
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Wrapper;
