import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";

const CardComponent = ({ header, body, path }) => {
  const theme = useTheme();
  console.log(path)
  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "10px",
        backgroundColor: theme.palette.lightBlack.main,
      }}
    >
      <Box width="100%" >
       
       <img
        src={`../../images/features/${path}.jpg`}
        alt={header}
        style={{ maxWidth: "100%", maxHeight: "100%", width: "auto", height: "15rem", objectFit:"cover"}}
      />
      </Box>
      <CardContent sx={{ }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            // fontFamily:  "'Roboto', sans-serif",
            letterSpacing: "0.02rem",
            color: "white",
            padding: "0.5rem 0.7rem 0rem 0.7rem"
          }}
        >
          {header}
        </Typography>
        <Typography
          sx={{
            fontFamily: "sans-serif",
            fontSize: "17px",
            letterSpacing: "0.02rem",
            color: theme.palette.silver.main,
            padding: "0.6rem 0.7rem 1rem 0.7rem",
          }}
        >
          {body}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CardComponent;
