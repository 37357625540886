import { useTheme } from "@emotion/react";
import { Box, Typography, Stack, Grid, Card } from "@mui/material";
import React from "react";
import DownloadButton from "./common/DownloadButton";
import downloadData from "./download_data.json";
import BasicCard from "./common/BasicCard";

const Header = ({ children }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Typography
        variant="h2"
        sx={{
          color: theme.palette.offWhite.main,
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
          textAlign: "center",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

function Download() {
  return (
    <Box
      className="download-tab"
      sx={{
        positin: "relative",
        flexGrow: 1,
        // height: "100%", // why this makes content zero height?
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        <Header>MECAnywhere is open-sourced by SBIP for the web3 community</Header>
        {/* <Header>for Windows</Header> */}
        {/* <DownloadButton isDownloadPage={true} /> */}
        {/* <Box sx={{ padding: "2rem 0", maxWidth: { xs: "90%", sm: "70%" } }}>
          <Typography
            variant="body2"
            sx={{ color: "red", textAlign: "center" }}
          >
            MECAnywhere will be released in July 2024! Stay tuned!
          </Typography>
        </Box> */}
        <Box sx={{ padding: "2rem 0" }}>
        <Grid container justifyContent="center">
        {downloadData.map((item, index) => (
          <Grid
            item
            xs={8}
            sm={6}
            md={3}
            key={index}
            sx={{
              padding: "1rem",
              display: "flex",
            }}
          >
            <BasicCard
              name={item.name}
              description={item.description}
              docUrl={item.docUrl}
              githubUrl={item.githubUrl}
            />
          </Grid>
        ))}
      </Grid>
      </Box>
        <Box sx={{ padding: "2rem 0", maxWidth: { xs: "90%", sm: "90%" } }}>
          <Typography
            variant="body2"
            sx={{ color: "white", textAlign: "center" }}
          >
            Contact us for demo and collaboration opportunities!
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default Download;
