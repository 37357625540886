import Download from "./components/Download";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import Pricing from "./components/Pricing";
import About from "./components/About";
import Wrapper from "./components/Wrapper"
import CoverImage from "./components/CoverImage";

function App() {
  

  return (
    <Router>
      <NavBar />
      <CoverImage />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/pricing" element={<Pricing />} /> */}
          <Route path="/download" element={<Download />} />
          {/* <Route path="/about" element={<About />} /> */}
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
