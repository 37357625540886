import cardData from "./card_data.json";
import CardComponent from "./CardComponent";
import { Box, Typography, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

const AppFeatures = () => {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Typography
          variant="h2"
          sx={{
            padding: "3rem 0 2rem 0.5rem",
            color: theme.palette.offWhite.main,
          }}
        >
          Seamless Edge, Seamless Experience.
        </Typography>
      </Box>
      <Grid container sx={{ paddingBottom: "7rem" }}>
        {cardData.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            sx={{
              padding: "1rem",
            }}
          >
            <CardComponent
              header={item.header}
              body={item.body}
              path={item.path}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AppFeatures;
