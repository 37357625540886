import { Box, Typography, Grid, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import Link from "@mui/material/Link";

const socialMediaIcons = [
  { src: "../../images/social/website.png", alt: "website", url: "https://sbip.sg/" },
  { src: "../../images/social/linkedin.png", alt: "linkedin", url: "https://www.linkedin.com/company/sbip-sg/" },
  { src: "../../images/social/facebook.png", alt: "facebook", url: "https://www.facebook.com/singaporeblockchaininnovationprogramme" },
  { src: "../../images/social/discord.png", alt: "discord", url: "https://discord.com/invite/KxA4kHTPN6" },
  { src: "../../images/social/instagram.png", alt: "instagram", url: "https://www.instagram.com/sbip.sg/" },
  { src: "../../images/social/youtube.png", alt: "youtube", url: "https://www.youtube.com/channel/UCqObnfOCpzqnr8m3ylg08VQ" },
];

const LinkText = ({ url, children }) => {
  const theme = useTheme();
  return (
    <Link
      href={url}
      color="inherit"
      sx={{ textDecoration: "none", padding: "0.1rem 0 0 0" }}
    >
      <Typography
        variant="body1"
        sx={{
          textAlign: { xs: "center", sm: "end" },
          color: "black",
          "&:hover": {
            color: theme.palette.violet.main,
            fontWeight: 600,
          },
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};

const LinkIcon = ({ index, src, alt, url }) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={{ padding: "0 0.5rem" }}>
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "auto",
          height: "2.3rem",
          objectFit: "cover",
          borderRadius: "6px"
        }}
      />
    </a>
  )
}

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        // height: "100%",
        // maxHeight: {xs: "100px", md: "100%"},
        // mt: "auto",
        backgroundColor: theme.palette.cerulean.main,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        sx={{
          width: "85rem",
          padding: "1rem",
        }}
      >
        <Grid className="left-footer-component" container item xs={12} sm={6} md={4} >
          <Stack>
            <Typography
              variant="body3"
              sx={{
                color: "black",
                paddingBottom: "1rem",
              }}
            >
              Copyright 2023 © Singapore Blockchain Innovation Programme. All
              rights reserved.
            </Typography>
            <Grid container spacing={1}>
              <Grid container item xs={4}>
                <Typography
                  variant="body3"
                  sx={{
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Email
                </Typography>
              </Grid>
              <Grid container item xs={8}>
                <Typography
                  variant="body3"
                  sx={{
                    color: "black",
                  }}
                >
                  contact@sbip.sg
                </Typography>
              </Grid>
              <Grid container item xs={4}>
                <Typography
                  variant="body3"
                  sx={{
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  Address
                </Typography>
              </Grid>
              <Grid container item xs={8}>
                <Typography
                  variant="body3"
                  sx={{
                    color: "black",
                  }}
                >
                  National University of Singapore < br />
                  11 Research Link < br />
                  COM3, #B1-16 < br />
                  Singapore 119391
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid
          container item xs={12} sm={6} md={8}
        >
          <Grid
            className="middle-footer-component"
            container
            item
            xs={12}
            md={6}
            sx={{ width: "100%", }}
          >
            <Stack sx={{
              width: "100%", padding: {
                xs: "2rem 0 0 0",
                md: "0 0 0 0"
              }
            }}>
              <Typography
                variant="h4"
                sx={{
                  paddingBottom: "0.3rem",
                  textAlign: { xs: "center", sm: "end" },
                  color: "black",
                }}
              >
                MECAnywhere
              </Typography>
              <LinkText className="link-text" url={"/"}>Features</LinkText>
              <LinkText className="link-text" url={"/download"}>Download</LinkText>
              {/* <LinkText className="link-text" url={"/about"}>About</LinkText> */}
            </Stack>
          </Grid>
          <Grid className="right-footer-component" container item xs={12} md={6}>
            <Stack sx={{
              width: "100%",
              padding: {
                xs: "2rem 0 0 0rem",
                md: "0 2rem 0 2rem"
              }
            }}>
              <Typography
                variant="h4"
                sx={{
                  textAlign: { xs: "center", sm: "end" },
                  color: "black",
                }}
              >
                Social
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "end" },
                  padding: "0.5rem 0 1rem 0",
                }}
              >
                {socialMediaIcons.map((icon, index) => (
                  <LinkIcon key={index} src={icon.src} alt={icon.alt} url={icon.url} />
                ))}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
};

export default Footer;

