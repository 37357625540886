import { Box, Typography, Button, Stack } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ReactComponent as SBIPLogo } from "../../images/sbipLogo.svg";

const Story = () => {
  const theme = useTheme();
  const handleClick = () => {
    var win = window.open("https://sbip.sg/", "_blank");
    win.focus();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "8rem",
      }}
    >
      <Box sx={{ maxWidth: "60rem" }}>
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            padding: "3rem 0 2rem 0.5rem",
            fontFamily: "sans-serif",
            letterSpacing: "0.1rem",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
          }}
        >
          Who We Are
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0 4rem 0",
            position: "relative",
            left: "-1.5rem",
          }}
        >
          <SBIPLogo width="70%" height="70%" />
        </Box>
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 1.2rem",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              fontFamily: "sans-serif",
              "&:first-letter": {
                fontFamily: "sans-serif",
                fontSize: "400%",
                fontWeight: 500,
                float: "left",
                lineHeight: 0.6,
              },
            }}
          >
            We are from{" "}
            <span
              style={{
                color: theme.palette.deepCerulean.main,
                fontWeight: 600,
              }}
            >
              Singapore Blockchain Innovation Programme (SBIP)
            </span>
            , a collaborative team of experts drawn from esteemed academic
            institutions like NUS and NTU, and fortified by strategic government affiliations in
            Singapore. Our team is dedicated to steering the course of
            blockchain technology toward innovative real-world applications.
          </Typography>

          <Button
            variant="outlined"
            sx={{
              marginTop: "2rem",
              color: theme.palette.offWhite.main,
              borderColor: "white",
              backgroundColor: theme.palette.darkBlack.main,
              width: "15rem",
              maxWidth: "15rem",
              borderRadius: "50px",
              "&:hover": {
                color: theme.palette.darkBlack.main,
                backgroundColor: theme.palette.mintGreen.main,
              },
            }}
            onClick={handleClick}
          >
            Read our story
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default Story;
